import gql from 'graphql-tag';
import { defineComponent } from 'vue';

const CREATE_CLIENTS_CSV_REQUEST = gql`
  mutation createClientsCsvRequest {
    createClientsCsvRequest
  }
`

export default defineComponent({
    name: 'CsvCreator',

    methods: {
        async sendLink() {
            try {
                this.$q.notify('Sending email')
                await this.$apollo.mutate({
                    mutation: CREATE_CLIENTS_CSV_REQUEST
                });
                this.$q.notify('Email sent')
            } catch (error) {
                console.error('Error creating csv request:', error);
            }
        }
    }
})
