import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "q-pa-lg"
}
const _hoisted_2 = { class: "q-pl-sm" }
const _hoisted_3 = { style: {"display":"contents"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_banner = _resolveComponent("q-banner")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.queryError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_q_banner, {
            rounded: "",
            class: "bg-red-2"
          }, {
            avatar: _withCtx(() => [
              _createVNode(_component_q_icon, {
                name: "error",
                color: "red"
              })
            ]),
            default: _withCtx(() => [
              _createTextVNode("An error occurred while trying to fetch referrers: "),
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.queryError), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_q_table, {
      class: "q-ma-sm",
      title: "Referrers",
      rows: _ctx.referrers,
      "sort-method": _ctx.sortWithDate,
      columns: _ctx.table.columns,
      "row-key": "name",
      loading: _ctx.$apollo.queries.referrers.loading,
      pagination: _ctx.table.pagination,
      "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.table.pagination) = $event)),
      "rows-per-page-options": [50, 100, 500, 1000, 0]
    }, {
      "top-right": _withCtx(() => [
        _createVNode(_component_q_btn, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$apollo.queries.referrers.refetch().catch())),
          loading: _ctx.$apollo.queries.referrers.loading,
          icon: "refresh",
          flat: "",
          round: ""
        }, null, 8, ["loading"])
      ]),
      body: _withCtx((props) => [
        _createElementVNode("span", _hoisted_3, [
          _createVNode(_component_q_tr, { props: props }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterColumnData(props.row), (columnData, name) => {
                return (_openBlock(), _createBlock(_component_q_td, { key: name }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.formatForDisplay(columnData, name as string)), 1)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 2
          }, 1032, ["props"])
        ])
      ]),
      _: 1
    }, 8, ["rows", "sort-method", "columns", "loading", "pagination"])
  ]))
}