import { ApolloError } from '@apollo/client'
import gql from 'graphql-tag'
import { defineComponent } from 'vue'

const queueMutation = gql`
  mutation queue($enqueueNew: Boolean!) {
    queue(enqueueNew: $enqueueNew) {
      id
      firstName
      lastName
      email
      status
      ClientType
    }
  }
`

const queueStatQuery = gql`
  query queueStat {
    queueStat {
      inProgress
      waiting
    }
  }
`

export default defineComponent({
    name: 'QueuePage',

    data() {
        return {
            isUser: false,
            isAdmin: false,
            queryError: null as ApolloError | null,
            clients: [] as any[],
            loading: false,
            table: {
                columns: [
                    { name: 'firstName', required: true, label: 'First Name', align: 'left' as const, field: 'firstName' },
                    { name: 'lastName', required: true, label: 'Last Name', align: 'left' as const, field: 'lastName' },
                    { name: 'email', label: 'Email', align: 'left' as const, field: 'email' },
                    { name: 'status', label: 'Status', align: 'left' as const, field: 'status' },
                    { name: 'ClientType', label: 'Client Type', align: 'left' as const, field: 'ClientType' },
                ],
            },
            queueStats: {
                inProgress: 0,
                waiting: 0
            }
        }
    },

    mounted() {
        this.fetchQueueStats()
        this.fetchClients(false);
        this.handleClientIdParam();
    },

    methods: {
        async releaseClient(clientId: string) {
            try {
                await this.$apollo.mutate({
                    mutation: gql`
                mutation ReturnClientToQueue($clientId: String!) {
                    returnClientToQueue(clientId: $clientId)
                }
            `,
                    variables: {
                        clientId
                    },
                    refetchQueries: [
                        { query: queueStatQuery }
                    ],
                    awaitRefetchQueries: true
                });
                this.$q.notify('Client returned to queue')

                // Refresh the queue without adding a new client
                await this.fetchClients(false);

                // Update queue stats
                const { data: statsData } = await this.$apollo.query({
                    query: queueStatQuery
                })
                if (statsData) {
                    this.queueStats = statsData.queueStat
                }
            } catch (error) {
                console.error('Error releasing client:', error);
            }
        },

        async fetchQueueStats() {
            try {
                const { data } = await this.$apollo.query({
                    query: queueStatQuery
                })
                if (data) {
                    this.queueStats = data.queueStat
                }
            } catch (error) {
                console.error("Failed to fetch queue stats: " + error)
            }
        },

        openClient(id: string) {
            this.$router.push(`/client/${id}?fromqueue=true`);
        },

        handleClientIdParam() {
            const clientId = this.$route.query.clientId as string;
            if (clientId) {
                this.releaseClient(clientId);
            }
        },

        async fetchClients(enqueueNew: boolean) {
            this.loading = true
            try {
                const { data } = await this.$apollo.mutate({
                    mutation: queueMutation,
                    variables: { enqueueNew },
                    refetchQueries: [
                        { query: queueStatQuery }
                    ],
                    awaitRefetchQueries: true
                })
                if (data) {
                    this.clients = data.queue.map((item: any) => ({
                        id: item.id,
                        firstName: item.firstName,
                        lastName: item.lastName,
                        email: item.email,
                        status: item.status,
                        ClientType: item.ClientType,
                    }))
                }

                // Fetch the updated queue stats
                const { data: statsData } = await this.$apollo.query({
                    query: queueStatQuery
                })
                if (statsData) {
                    this.queueStats = statsData.queueStat
                }

                this.queryError = null
            } catch (error) {
                console.error("Failed to fetch clients: " + error)
            } finally {
                this.loading = false
            }
        },
        async checkoutClient() {
            try {
                await this.fetchClients(true)
            } catch (error) {
                console.error("Failed to checkout client: " + error)
            }
        }
    }
})
