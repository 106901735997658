import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "flex flex-row p-4 gap-2" }
const _hoisted_2 = { class: "flex flex-col gap-2" }
const _hoisted_3 = ["onClick", "href"]
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "text-sm text-gray-700" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_icon = _resolveComponent("q-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_q_input, {
        modelValue: _ctx.clientEmail,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.clientEmail) = $event)),
        label: "Client Email"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_q_input, {
        modelValue: _ctx.query,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.query) = $event)),
        label: "Search Query"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_q_input, {
        modelValue: _ctx.cseOptions.threshold,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.cseOptions.threshold) = $event)),
        modelModifiers: { number: true },
        type: "number",
        label: "Threshold"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_q_checkbox, {
        modelValue: _ctx.cseOptions.name,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.cseOptions.name) = $event)),
        label: "Name"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_q_checkbox, {
        modelValue: _ctx.cseOptions.streetAddress,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.cseOptions.streetAddress) = $event)),
        label: "Street Address"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_q_checkbox, {
        modelValue: _ctx.cseOptions.zipCode,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.cseOptions.zipCode) = $event)),
        label: "Zip Code"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_q_checkbox, {
        modelValue: _ctx.cseOptions.city,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.cseOptions.city) = $event)),
        label: "City"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_q_checkbox, {
        modelValue: _ctx.cseOptions.state,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.cseOptions.state) = $event)),
        label: "State"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_q_btn, {
        class: "m-2",
        onClick: _ctx.search
      }, {
        default: _withCtx(() => [
          _createTextVNode("Search")
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_q_input, {
        modelValue: _ctx.filterResults,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.filterResults) = $event)),
        label: "Filter results"
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.results.filter(({ link }) => link.includes(_ctx.filterResults)), (result) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "bg-gray-200 p-2 mx-2 rounded",
          key: result.link
        }, [
          _createElementVNode("div", {
            onClick: ($event: any) => (_ctx.showFullData(result.fullData)),
            class: "text-base",
            href: result.link,
            target: "_blank"
          }, [
            _createVNode(_component_q_icon, {
              name: result.included ? 'check' : 'cancel'
            }, null, 8, ["name"]),
            _createTextVNode(" " + _toDisplayString(result.title), 1)
          ], 8, _hoisted_3),
          _createElementVNode("a", {
            href: result.link,
            target: "_blank",
            class: "text-sm text-blue-700"
          }, _toDisplayString(result.link), 9, _hoisted_4),
          _createElementVNode("div", _hoisted_5, _toDisplayString(result.snippet), 1)
        ]))
      }), 128))
    ])
  ]))
}