import DeepScanResults from '@/components/DeepScanResults/DeepScanResults.vue'
import DeepScanHits from '@/views/DeepScansHits/DeepScanHits.vue'
import Reports from '@/views/Reports/Reports.vue'
import { createRouter, createWebHistory } from 'vue-router'
import SiteStatusesTable from './components/SiteStatusesTable/SiteStatusesTable.vue'
import SiteStatusView from './components/SiteStatusView/SiteStatusView.vue'
import DefaultLayout from './layouts/Default.vue'
import RequestBillingEmail from './views/BillingDashboard/RequestBillingEmail.vue'
import ChangePassword from './views/ChangePassword/ChangePassword.vue'
import Client from './views/Client/Client.vue'
import ClientList from './views/ClientList/ClientList.vue'
import CseTest from './views/CseTest/CseTest.vue'
import CsvCreator from './views/CsvCreator/CsvCreator.vue'
import InstructionDocuments from './views/InstructionDocuments/InstructionDocuments.vue'
import Loading from './views/Loading/Loading.vue'
import Login from './views/Login/Login.vue'
import Monitoring from './views/Monitoring/Monitoring.vue'
import Queue from './views/Queue/Queue.vue'
import QueueLog from './views/QueueLog/QueueLog.vue'
import Referrers from './views/Referrers/Referrers.vue'
import RequestPasswordResetLink from './views/RequestPasswordResetLink/RequestPasswordResetLink.vue'
import SecurityEvents from './views/SecurityEvents/SecurityEvents.vue'
import SignUp from './views/SignUp/SignUp.vue'
import SiteList from './views/SiteList/SiteList.vue'
import User from './views/User/User.vue'
import UserList from './views/UserList/UserList.vue'

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'home',
            component: DefaultLayout,
            children: [
                {
                    path: '/loading',
                    name: 'Loading',
                    component: Loading,
                },
                {
                    path: '/login',
                    name: 'Login',
                    component: Login,
                },
                {
                    path: '/request-billing-dashboard',
                    name: 'RequestBillingEmail',
                    component: RequestBillingEmail,
                },
                {
                    path: '/signup',
                    name: 'SignUp',
                    component: SignUp,
                },
                {
                    path: '/clients',
                    name: 'Clients',
                    component: ClientList,
                },
                {
                    path: '/queue',
                    name: 'Queue',
                    component: Queue,
                },
                {
                    path: '/csvcreator',
                    name: 'CsvCreator',
                    component: CsvCreator,
                },
                {
                    path: '/queuelog',
                    name: 'QueueLog',
                    component: QueueLog,
                },
                {
                    path: '/users',
                    name: 'Users',
                    component: UserList,
                },
                {
                    path: '/user/:userId',
                    name: 'User',
                    component: User,
                },
                {
                    path: '/sites',
                    name: 'Sites',
                    component: SiteList,
                },
                {
                    name: 'deep-scan-hits',
                    path: '/deep-scan-hits',
                    component: DeepScanHits,
                },
                {
                    name: 'reports',
                    path: '/reports',
                    component: Reports,
                },
                {
                    path: '/instructionDocuments',
                    name: 'InstructionDocuments',
                    component: InstructionDocuments,
                },
                {
                    path: '/security',
                    name: 'Security',
                    component: SecurityEvents,
                },
                {
                    path: '/referrers',
                    name: 'Referrers',
                    component: Referrers,
                },
                {
                    path: '/monitoring',
                    name: 'Monitoring',
                    component: Monitoring,
                },
                {
                    path: '/requestPasswordResetLink',
                    name: 'requestPasswordResetLink',
                    component: RequestPasswordResetLink,
                },
                {
                    path: '/changePassword',
                    name: 'changePassword',
                    component: ChangePassword,
                },
                {
                    path: '/cseTest',
                    name: 'cseTest',
                    component: CseTest,
                },
                {
                    name: 'client',
                    path: '/client/:clientId',
                    component: Client,
                    children: [
                        {
                            path: '',
                            component: SiteStatusesTable,
                        },
                        {
                            path: 'site/:siteId',
                            component: SiteStatusView,
                        },
                        {
                            name: 'deep-scan-results',
                            path: 'deep-scan-results/:siteId',
                            component: DeepScanResults,
                        },
                    ],
                },
                {
                    path: '/client-more-info/:clientId',
                    name: 'ClientMoreInfo',
                    component: () => import('@/views/ClientMoreInfo/ClientMoreInfo.vue'),
                    meta: { layout: 'blank' }
                }
            ],
        },
    ],
})

router.afterEach((to) => {
    if (to.path !== '/loading' && process.env.NODE_ENV === 'production' && gtag) {
        gtag('config', window.GA_TRACKING_ID, {
            page_path: to.fullPath,
            send_page_view: true,
        })
    }
})

export default router
